@import 'assets/scss/variables';

.manage-tab-content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  margin-top: 24px;
  height: 100%;

  .manage-tab-content-wrapper-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    overflow-x: auto;

    .manage-tab-content-table-wrapper {
      height: 100%;

      .__table-wrapper {
        width: 100%;
        min-width: 1200px;
        overflow: visible;
        grid-template-columns: auto;
        box-shadow: none;

        .__table_cell-header {
          color: #444444;

          opacity: 0.5;

          &:first-child {
            opacity: 1;
          }
        }

        .__table_cell {
          transition: 0.2s;

          white-space: nowrap;

          font-weight: 100;

          font-size: 12px;
          text-align: left;
        }

        .__table_body {
          .__table_row {
            &.canSelected {
              &:hover {
                background-color: unset;

                .__table_cell {
                  transition: 0.2s;

                  color: $Cerulean;
                }
              }
            }

            .__name {
              font-weight: 900;
              max-width: 250px;

              .__siteName_cell_wrapper {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .__campaignDates,
            .__times,
            .__dayParting {
              color: $Cerulean;

              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
