.view-all-media-dialog {
  .swiper-button {
    position: absolute;
    z-index: 2;

    &.swiper-prev {
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }

    &.swiper-next {
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
  }

  .swiper-pagination {
    bottom: 0;
  }
}
