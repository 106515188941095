.main-layout {
  transition: padding 0.5s ease;
  max-width: 100vw;

  &.isSmall {
    padding-left: 70px;
  }

  &.isMedium {
    padding-left: 270px;
  }

  &.gridForTwoElements {
    display: grid;
    grid-template-rows: auto minmax(600px, 1fr);

    padding-bottom: 25px;
  }
}
