@import 'assets/scss/variables';

.bulk-actions-container {
  display: flex;
  align-items: center;

  margin-right: 20px;
}

.bulk-actions-button {
  padding: 8px;

  letter-spacing: 1px;

  .text-button {
    color: $Cerulean;
  }
}

.bulk-icon-container-closed {
  transform: rotate(90deg);
}

.bulk-icon-container-opened {
  transform: rotate(270deg);

  width: 22px;
  height: 22px;
}

.bulk-actions-icon {
  stroke: unset !important;
}
