@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.table-root {
  @include table-container-root;
  height: 100%;
}

.modal-root {
  @include table-container-root;
  position: static;
  height: 100%;
}

.more-icon-container {
  display: flex;
  align-items: center;

  & > button {
    padding: 6px;
  }
}

.table-cell {
  padding: 16px 8px !important;

  font-size: $s-common-font-size;
  line-height: 16px;
  color: $Tundora;

  border: none;
}

.table-row-cell {
  padding: 16px 8px !important;

  font-size: $s-common-font-size !important;
  color: $Tundora;
}

.table-image {
  width: 40px;
  height: 60px;

  object-fit: cover;
}

.table-video-image {
  width: 40px;
  height: 60px;

  object-fit: contain;
}

.table-image::before {
  content: ' ';

  position: absolute;
  z-index: 10;

  display: block;

  width: 40px;
  height: 60px;

  background-image: url(../../../assets/images/no-img-available.png);
  background-size: 40px 60px;
}

.table-image::before {
  content: ' ';

  position: absolute;
  z-index: 10;

  display: block;

  width: 40px;
  height: 60px;

  background-image: url(../../../assets/images/no-img-available.png);
  background-size: 40px 60px;
}

.footer-container {
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;

  width: 100%;

  background-color: $lightShadeOfGray;
  border-top: 1px solid #ccc;
}

.without-border {
  border: none;
}

.void-table-header-table-cell {
  width: 48px;
  height: 48px;
}

.center-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin-right: -50%;
}

.table-cell-icon {
  color: $Cerulean;

  #Path_38,
  #Rectangle_138,
  #Path_45,
  #Path_46,
  #Line_38 {
    stroke: $Cerulean !important;
  }
}
