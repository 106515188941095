@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.filter-campaign-container {
  overflow-y: auto;

  box-sizing: border-box;
  max-width: 310px;
  margin-right: 3px;
  padding: 0 12px;

  box-shadow: 0 3px 10px $SilverChalice;
}

.opened-campaign-filter-container {
  width: 100%;
}

.icon-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  min-width: 90%;
  padding-top: 10px;

  svg {
    color: $Cerulean;
  }
}

.table-filter {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 15px;

  opacity: 1;
  background: $White 0% 0% no-repeat padding-box;

  .filter-container {
    min-width: 90%;
    padding-top: 15px;

    .title-container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
    }

    .filter-title {
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 20px;

      font: normal normal bold 18px $header-font;
      color: $Cerulean;
    }

    .clear {
      height: 30px;
      padding: 0;

      font-size: $common-font-size;
      font-weight: 400;
      text-decoration: underline;
      text-transform: capitalize;
      letter-spacing: unset;

      .text-button {
        color: $Cerulean;
      }
    }
  }

  .filters-container {
    min-width: 90%;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .filter-items-container {
    width: 100%;
    padding-top: 0;

    background-color: $White;

    &__list-item {
      box-sizing: border-box;
      min-height: 32px;

      font-size: 14px;
    }

    &__list-item-text {
      overflow: hidden;

      height: 20px;
      padding-right: 55px;

      white-space: pre-wrap;
    }

    &__list-item-text:hover {
      height: fit-content;

      .MuiListItemText-primary:hover {
        z-index: 100;

        overflow: visible;
      }
    }

    &__secondary-item-text {
      right: 8px;
    }
  }

  .accordion-item {
    @include accordion-root;
  }

  .accordion-item-active {
    @include accordion-root;
    background-color: $LightBlue;
  }
  @media (max-width: 1000px) {
    min-width: stretch;
  }
}

.search-input-styles {
  min-width: 100%;
  height: 42px;

  & > label {
    top: -6px;

    font-size: $common-font-size;
  }

  & > div {
    height: 42px;

    & > input {
      height: 42px;
      padding: 0 20px;

      font-size: $s-common-font-size;
    }
  }
}

.full-width {
  width: 100%;
}

.width-45 {
  width: 45%;
  min-width: 45%;
}
