@import 'assets/scss/variables';

.__table_body {
  .__table_row {
    &.canSelected {
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        transition: 0.3s ease;
        background-color: $Cerulean30;
      }
    }

    .__table_cell {
      .__cell_block {
        display: flex;

        &_body {
          &.__center {
            align-items: center;
            justify-content: center;
          }

          &.__center_horizontal {
            justify-content: center;
          }

          &.__center_vertical {
            align-items: center;
          }

          &.__right {
            justify-content: flex-end;
          }
        }

        &.__max-width {
          &-250 {
            max-width: 250;
          }
        }
      }

      &_p {
        // general paddings
        &10 {
          padding: 10px;
        }

        // top paddings
        &t10 {
          padding-top: 10px;
        }

        // bottom paddings
        &b10 {
          padding-bottom: 10px;
        }

        // side paddings
        &s10 {
          padding: 0 10px;
        }

        &s20 {
          padding: 0 20px;
        }
      }
    }
  }
}
