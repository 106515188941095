@import 'assets/scss/variables';
@import 'assets/scss/mixins.scss';

.sites-container {
  @include page-container-default;

  .sites-header {
    @include base-header-styles;
  }
}

.edit-site-icon {
  #Rectangle_137,
  #Path_40 {
    stroke: $Cerulean;
  }
}
