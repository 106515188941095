/*  https://fonts.google.com/specimen/Poppins */

/*  <-----------------------Thin-----------------------> */

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin-Italic';
  src: url('../fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

/*  <-----------------------ExtraLight-----------------------> */

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight-Italic';
  src: url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/*  <-----------------------Light-----------------------> */

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight-Italic';
  src: url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/*  <-----------------------Regular-----------------------> */

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular-Italic';
  src: url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

/*  <-----------------------Medium-----------------------> */

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium-Italic';
  src: url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

/*  <-----------------------SemiBold-----------------------> */

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold-Italic';
  src: url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/*  <-----------------------Bold-----------------------> */

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold-Italic';
  src: url('../fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

/*  <-----------------------ExtraBold-----------------------> */

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold-Italic';
  src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

/*  <-----------------------Black-----------------------> */

@font-face {
  font-family: 'Poppins-Black';
  src: url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Black-Italic';
  src: url('../fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

/* Akkurat */

/*  <-----------------------Light-----------------------> */

@font-face {
  font-family: 'Akkurat-Light';
  src: url('../fonts/Akkurat/Akkurat-Light.ttf') format('truetype');
}

/*  <-----------------------Regular-----------------------> */

@font-face {
  font-family: 'Akkurat-Regular';
  src: url('../fonts/Akkurat/Akkurat.ttf') format('truetype');
}

/*  <-----------------------Bold-----------------------> */

@font-face {
  font-family: 'Akkurat-Bold';
  src: url('../fonts/Akkurat/Akkurat-Bold.ttf') format('truetype');
}
