@import 'assets/scss/variables';

.message-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;

  left: -225px;

  .message-container {
    background-color: $LilyWhite;
    padding: 16px;
    border-radius: 16px;
  }

  @media (max-width: 1215px) {
    margin-bottom: 24px;
    position: static;
    left: unset;
    width: 100%;

    .message-container {
      max-width: 75%;
    }
  }
}
