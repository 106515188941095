.wrapper-resolution {
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  width: 100%;

  .select-wrapper {
    width: 100%;
  }

  .new-resolution-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .select-wrapper {
      width: 300px;
    }
  }
}
