@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.icon-text {
  @include icon-text-root;
  color: $Cerulean;
}

.icon-text-error-type {
  @include icon-text-root;
  color: $Red;
}
