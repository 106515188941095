.sites-content {
  display: flex;
  flex-direction: column;

  padding: 0 55px 0 25px;

  margin-bottom: 50px;

  gap: 15px;
}
