@import 'assets/scss/variables';

.list-container {
  width: 200px;
}

.menu-list {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.menu-list-item {
  color: $Tundora;
}

.menu-text {
  font-size: $common-font-size !important;
}
