.no-site-container {
  display: flex;
  flex-direction: column;

  align-items: center;

  margin-top: 24px;

  gap: 20px;
}
