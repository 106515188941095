@import 'assets/scss/variables';

.select-wrapper {
  display: flex;
  flex-direction: column;

  .select-label {
    font-family: $common-font;
    font-weight: 600;
    font-size: 16px;
    color: $Cerulean;
    letter-spacing: 1.6px;
  }

  .select-helper-text {
    font-family: $common-font;
    font-size: 16px;

    &.error {
      color: $Red;
    }
  }
}
