@import 'assets/scss/variables';

.day-parts-wrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;

  .day-part-item {
    position: relative;
    display: flex;

    border: 1px solid #ccc;
    border-radius: 16px;

    padding: 12px;

    overflow: hidden;

    .day-part-item-content {
      display: flex;
      flex-direction: column;

      gap: 16px;
    }

    .remove-day-part {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      transition: 0.2s;
      background: none;
      border: 0;
      box-shadow: none;

      &:hover:not(:disabled) {
        transition: 0.2s;
        background-color: $Red30;
        color: $Red;
      }
    }
  }
}
