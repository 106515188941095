@import 'assets/scss/variables';

.preview-modal-container {
  width: fit-content;
  min-width: 400px;
  height: fit-content;
  min-height: 400px;

  outline: none;

  .preview-inner-modal-container {
    position: relative;

    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 300px;
    padding: 30px;

    opacity: 1;
    background: $White 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px $SilverChalice;

    .close-modal-button {
      position: absolute;
      top: 7px;
      right: 7px;

      padding: 0;

      background-color: white;
    }

    .preview-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;

      .video-player {
      }

      .preview-img {
        width: fit-content;
        max-width: 80vh;
        height: fit-content;
        max-height: 80vh;

        object-fit: contain;
      }

      .preview-img::before {
        content: ' ';

        position: absolute;
        z-index: 10;
        top: 25%;
        left: 12%;

        display: block;

        width: 300px;
        height: 175px;

        background-image: url(../../../../assets/images/no-img.jpeg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 300px 175px;
      }

      .play-button {
        position: relative;

        background-color: white;
      }

      .MuiIconButton-root:hover {
        background-color: white;
      }
    }
  }
}
