.__table-actions {
  position: relative;
  align-items: center;

  min-height: 75px;
  min-width: 800px;

  padding: 0 12px;

  box-sizing: border-box;
  border-bottom: 1px solid #444;
  display: none;

  .__search_wrapper {
    width: 50%;
  }

  .__actions_wrapper {
    position: absolute;

    right: 15px;
  }

  &.show {
    display: flex;
  }
}

.MuiTextField-root {
  height: unset;
}
