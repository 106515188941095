.manage-tab-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .header-manage-tab-block {
    display: flex;

    align-items: center;
    gap: 16px;

    &.manage-tab-header-right {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}
