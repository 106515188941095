.additionally-staff-tab-panel {
  .content {
    display: flex;
    flex-direction: column;

    gap: 20px;

    &.resolution-content {
      .resolution-values {
        display: flex;

        gap: 20px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0;

    gap: 20px;

    button {
      color: white;
    }
  }
}
