@import 'assets/scss/variables';

.campaign-form {
  display: flex;
  flex-direction: column;

  gap: 20px;

  .or-divider-wrapper {
    --divider-wrapper-height: 50px;
    --divider-height: 2px;

    position: relative;

    height: var(--divider-wrapper-height);

    .divider {
      position: absolute;
      height: var(--divider-height);

      top: calc(var(--divider-wrapper-height) / 2 - var(--divider-height));

      width: 100%;
      background-color: $Gray;
    }

    .or-span {
      position: absolute;
      background-color: $White;
      color: $Gray;

      left: 50%;
      top: 50%;

      padding: 12px 24px;

      transform: translate(-50%, -50%);
    }
  }
}
