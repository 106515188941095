.campaign-content-wrapper {
  display: grid;

  grid-template-rows: auto 1fr;

  height: 100%;

  padding-bottom: 20px;
  box-shadow: 0 0 15px #00000025;
}
