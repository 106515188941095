@import 'assets/scss/variables';

.add-media-modal-dropzone {
  &::after {
    content: none;
  }

  &.isActive::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $Cerulean30;
    border: 2px dashed $Cerulean;

    transition: 0.2s;
  }

  .inner-add-media-modal-dropzone {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .dropzone-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      border: 2px dashed $Cerulean;

      width: 90%;
      height: 85%;

      .dropzone-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
