@import 'assets/scss/variables';

.all-media-slide-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .image-wrapper {
    display: flex;
    justify-content: center;
    padding: 24px 20px 0;
    min-height: 200px;

    .slider-image {
      width: 100%;
      max-height: 70vh;
      object-fit: contain;
    }
  }

  .video-wrapper {
    display: flex;
    justify-content: center;

    padding: 24px 20px 0;
    min-height: 200px;
  }

  .all-media-footer {
    display: grid;

    width: 100%;
    padding: 0 24px 24px;
    background-color: $Catskill;

    grid-template-columns: 1fr 1fr;

    .base-media-info {
      gap: 10px;
    }

    .footer-info-block {
      display: flex;
      flex-direction: column;

      justify-content: center;

      &.related-sites-list-wrapper {
        display: flex;
        flex-direction: column;

        .related-sites-list {
          max-height: 100px;
          margin-right: 20px;
          overflow: auto;
        }
      }
    }
  }
}
