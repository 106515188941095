@import './variables';

@mixin table-container-root {
  position: relative;

  overflow: hidden;

  width: 100%;

  .table-container {
    overflow: auto;

    width: 100%;
    height: 100%;

    .table {
      height: 50px;
      margin-bottom: 52px;
    }
  }
}

@mixin accordion-root {
  color: $Tundora;

  box-shadow: none;

  &__number-container {
    min-width: 32px;
    padding: 4px 6px;

    font-size: $common-font-size;
    text-align: center;

    background-color: $SkyBlue;
    border-radius: 6px;
  }

  &__filter-item-checkbox {
    padding: 9px 9px 9px 0;
  }

  &__heading {
    font-size: 15px;
    font-weight: 600;
  }
}

@mixin icon-text-root {
  padding-left: 15px;

  font-size: 16px;
}

@mixin page-container-default {
  display: flex;
  flex-flow: column;

  padding: 0 55px 40px 25px;
}

@mixin base-header-styles {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-right: 35px;

  .h1-header {
    margin: 30px 0;

    font: normal normal bold 4vw $header-font;
    color: abbey;
    text-align: left;
    letter-spacing: 0;

    opacity: 1;
  }
}
