@import 'assets/scss/variables';

.rdp {
  --rdp-accent-color: #00b6f1;
}

.rdp {
  margin: 0;
  width: min-content;
}

.coordinate {
  border-radius: 8px;
  padding: 15px;

  background-color: $White;
  box-shadow: 0 0 15px $Black50;
}
