@import 'assets/scss/variables';

.decline-dialog-container {
  .sub-text {
    font-family: $common-font;
    font-size: 16px;
    font-weight: 600;
    color: $Cerulean;
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }

  .dismiss-button {
    font-weight: 400;
    color: $Cerulean;
    text-decoration: underline;
    text-transform: capitalize;
    letter-spacing: unset;
  }
}
