@import 'assets/scss/variables';

.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
}

.text {
  .MuiTypography-root {
    padding-top: 4px;

    font-size: $common-font-size;
    font-weight: 600;
    color: $White;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;

    opacity: 1;
  }
}

.list-item {
  box-sizing: content-box;
  min-width: 30px;
  padding: 12px 0;

  color: $White;

  & > a {
    text-decoration: none;
  }

  &:hover {
    background-color: $Cerulean !important;
  }
}

.list-item-icon {
  box-sizing: content-box;
  min-width: 30px;
  padding: 12px 0 12px 12px;
}

.sidebar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 150px;
  padding-bottom: 30px;
  padding-top: 15px;

  .logo {
    height: 125px;
    max-width: 225px;

    &.Caneland {
      height: unset;
    }
  }

  .logo-no-img {
    content: '';

    position: absolute;
    top: 10px;
    left: 15px;

    display: block;

    width: 210px;
    height: 134px;

    background-image: url(../../assets/images/login-logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 210px 28px;
  }
}
