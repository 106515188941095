$White: #fff;
$Cerulean: #00b6f1;
$Cerulean50: #00b5f187;
$Cerulean30: #00b5f14f;
$Cerulean10: #00b5f110;
$Mercury: #e2e2e2;
$LightBlue: rgba(0, 182, 241, 0.1);
$OpacityBlack: rgba(0, 0, 0, 0.6);
$TransparentBlue: rgba(0, 182, 241, 0.35);
$Black: #000;
$Black50: #00000050;
$Gray: #e5e5e5;
$SilverChalice: #aaaaaa29;
$Silver: #c7c7c7;
$Emerald: #4ccf68;
$DarkEmerald: #2faa4a;
$Abbey: #48494a;
$LilyWhite: #e5f8fe;
$Tundora: #444;
$Red: #ff0000;
$Red50: #ff000050;
$Red30: #ff000030;
$DarkRed: #d60000;
$Azure: #0091f1;
$Catskill: #f1f5f8;
$Error: #ff0023;
$Shadow: #aaaaaa58;
$lightShadeOfGray: #fafafa;
$SkyBlue: #e5f3fd;
$DarkShadeOfRed: #4c4c4c;
$input-border: rgba(0, 0, 0, 0.23);
$media-selection-width: 60%;
$common-font: 'Akkurat-Regular', sans-serif;
$common-font-size: 16px;
$table-font-size: 14px;
$s-common-font-size: 12px;
$xs-common-font-size: 10px;
$header-common-font-size: 22px;
$header-font: 'Poppins-Bold', sans-serif;
$first-header-font-size: 42px;
$second-header-font-size: 23px;
$third-header-font-size: 17px;
$hoverAnimation: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
$text-shadow-hover: 0 0 11px rgba(150, 150, 150, 1);

$soft-shadow: 0 0 5px 5px #00000010;
