.campaign-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .__children-many-element-default-container {
    display: flex;
    gap: 20px;
  }
}
