@import 'assets/scss/_variables.scss';

.__table-wrapper {
  --table-wrapper-padding: 12px;
  --table-pagination-height: 75px;

  position: relative;
  display: grid;
  grid-template-columns: minmax(800px, 1fr);
  grid-template-rows: auto 1fr auto;
  overflow: auto;

  background-color: $White;
  box-shadow: 0 0 5px 5px #00000020;
  padding: var(--table-wrapper-padding);
  border-radius: 5px;

  &.w-full {
    width: 100%;
  }

  .__table {
    min-width: 800px;
    width: 100%;

    th {
      position: relative;

      &.canSorted {
        cursor: pointer;
      }

      .__cell_sort_chevron {
        position: absolute;
        right: 0;
        top: calc(44px / 2 - 15px);
      }
    }
  }

  .__max_width {
    &-160 {
      max-width: 160px;
    }
  }

  p {
    margin: 0;
  }
}
