.checkbox-icon {
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-size: cover;

  &.checkbox-done {
    background-image: url('../../../../assets/icons/done-icon.svg');
  }

  &.checkbox-indeterminate {
    background-image: url('../../../../assets/icons/removeIcon.svg');
  }
}
