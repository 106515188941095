@import 'assets/scss/variables';

.container {
  display: flex;
  flex-flow: column;

  margin: 0 55px 0 25px;
  padding-bottom: 40px;

  .campaigns-body {
    height: calc(100% - 103px);
  }

  .tab-panel {
    height: calc(100% - 62px);
  }

  .tab-box {
    height: 100%;
  }

  .new-campaign-controllers {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 78px;

    background-color: #f1f5f8;
  }

  .campaign-button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;

    color: $White;

    opacity: 1;
    background: $Cerulean 0% 0% no-repeat padding-box;
    border-color: $Cerulean;
    border-radius: 10px;
    box-shadow: 0 3px 10px $SilverChalice;

    .campaign-button-span {
      padding-left: 5px;

      font-size: 16px;
      color: $White;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 1.6px;

      opacity: 1;
    }
  }

  .campaign-button[disabled] {
    opacity: 1;
    background: $Silver 0% 0% no-repeat padding-box;
    border-color: $Silver;
    border-radius: 10px;
  }

  .publish-button {
    margin-left: 30px;

    background: $Emerald 0% 0% no-repeat padding-box;
    border-color: $Emerald;

    &:hover,
    &:focus {
      background: $DarkEmerald 0% 0% no-repeat padding-box;
      border-color: $DarkEmerald;
    }
  }

  .button-span {
    padding-left: 5px;

    font-size: 16px;
    color: $White;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.6px;

    opacity: 1;
  }

  .button[disabled] {
    opacity: 1;
    background: $Silver 0% 0% no-repeat padding-box;
    border-color: $Silver;
    border-radius: 10px;
  }

  .whiteSVG {
    fill: $White;
    stroke: $White;

    #adjustments {
      stroke: $White !important;
    }
  }

  .activeSVG {
    fill: $Cerulean;
    stroke: $Cerulean;

    #adjustments {
      stroke: $Cerulean !important;
    }
  }

  .campaigns-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-right: 35px;

    .h1-header {
      margin: 30px 0;

      font: normal normal bold 4vw $header-font;
      color: abbey;
      text-align: left;
      letter-spacing: var(--unnamed-character-spacing-0);
      letter-spacing: 0;

      opacity: 1;
    }
  }

  .drop-down {
    width: 161px;
    height: 16px;
    margin-right: 35px;

    color: $Cerulean;
  }

  #select-labe {
    color: $Cerulean !important;
  }
}

.controllers {
  display: flex;
}

.icon-xxxl-new-campaign {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

.button-label {
  display: flex;
  align-items: center;

  font-size: $common-font-size;
}

.table-container {
  display: flex;

  min-height: 300px;
  height: 100%;

  opacity: 1;
  background: $White 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 10px $SilverChalice;
  @media (max-width: 1000px) {
    flex-flow: row wrap;
  }
}

.MuiTabs-indicator {
  bottom: 3px;

  background-color: $Cerulean;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $Cerulean;
}

.campaign-body {
  .MuiPaper-root.makeStyles-paper-7.MuiPaper-elevation1.MuiPaper-rounded {
    margin-bottom: 0;
  }
}
