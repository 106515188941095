@import 'assets/scss/variables';

.change-organisations-page {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: $OpacityBlack;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 1000px;
    height: 550px;
    padding-top: 20px;

    background-color: $White;

    .title {
      width: 60%;
      margin-bottom: 10px;

      font-family: $header-font;
      font-size: 30px;
      color: $DarkShadeOfRed;
      text-align: center;
    }

    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
    }

    .container-blocks {
      overflow: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;

      width: 100%;
      height: 100%;

      .organisation-logo-block {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .organisations_logo {
        align-items: center;

        width: 148px;
        height: 92px;
        margin-bottom: 20px;
      }

      .organisations_logo-hidden {
        display: none;
      }

      .error-logo {
        margin: 25px 0 36px;
      }

      .organisation-block {
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 160px;
        height: 150px;
        margin: 15px 10px;
        padding-top: 15px;
      }

      .organisation-block:hover {
        box-shadow: 0 0 8px 6px $Silver;
      }

      .organisation-block:focus {
        outline: none;
        box-shadow: 0 0 8px 6px $TransparentBlue;
      }

      .organisation-name {
        font-family: $header-font;
        font-size: 18px;
        color: $OpacityBlack;
      }
    }
  }
}
