@import './assets/scss/fonts';
@import './assets/scss/variables';

.App {
  display: grid;
  grid-template-rows: 0 1fr;
  min-height: 100vh;
  background-color: $Catskill;
  font-size: $common-font-size;
  font-family: $common-font, sans-serif;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

.first-header {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 42px;
  color: $Tundora;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 26px;
  margin-bottom: 21px;
}

.second-header {
  font: normal normal bold $second-header-font-size $header-font;
  color: $Tundora;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 19px;
  margin-bottom: 14px;
}

.info-box {
  position: absolute;
  line-height: 1.3;
  margin: 30px 0 0 3%;
  box-sizing: border-box;
  width: 218px;
  height: fit-content;
  padding: 15px;
  font-size: $common-font-size;
  background: $LilyWhite 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: $Tundora;
  @media (max-width: 1500px) {
    width: 40%;
    position: static;
    margin: 30px auto 15px;
  }
}

.link {
  color: $Cerulean;
  text-decoration: underline;
  cursor: pointer;
  font-size: $s-common-font-size;
}

.info-box_inline {
  width: 50%;
  position: static;
}

.white-svg {
  stroke: $White;
  color: $White;
}

.checkbox-default {
  color: #e2e2e2;
}

.text-field-custom-icon {
  position: absolute;
  right: 18px;
  color: lightgray;
  height: 22px;
  width: 22px;
}

.radio-button {
  font-size: $common-font-size;
}

.dark-icon {
  #adjustments,
  #Path_42,
  #Path_43,
  #Line_37 {
    stroke: rgba(0, 0, 0, 0.54) !important;
  }
}

.active-icon {
  color: $Cerulean;
  #adjustments,
  #Path_42,
  #Path_43,
  #Line_37 {
    stroke: $Cerulean !important;
  }
}

//Auth layout
.login-wrapper {
  margin-left: 0;
  .content-group {
    width: 60%;
    max-width: 500px;
    position: relative;
  }

  .content-group-xl {
    width: 85%;
    max-width: 600px;
  }

  .logo-container {
    left: -24px;
    position: absolute;
  }

  .custom-logo-container {
    left: -24px;
    top: -90px;
    position: absolute;
  }

  .logo {
    width: 100%;
    max-width: 400px;
  }

  .login-image {
    background-image: url('./assets/images/test.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login_header {
    margin: 80px 0 56px;
    color: $White;
    @media (max-width: 950px) {
      text-align: center;
    }
  }

  .form-section {
    min-height: 100vh;
    background-color: $Abbey;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-content {
    margin: 0 auto;
  }

  .input-label {
    text-align: left;
    font-size: $common-font-size;
    font-weight: 700;
    letter-spacing: 1.6px;
    color: $White;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 8px;
  }

  .reset-input-wrapper {
    margin-right: 30px;
    width: 100%;
  }

  .input-container {
    display: flex;
    flex-flow: column;
    min-height: 98px;
    width: 85%;
  }

  .input-container-reset {
    display: flex;
    flex-flow: column;
    min-height: 98px;
    width: 100%;
  }

  .button-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: left;
    padding-bottom: 38px;
  }

  .button-group_top-margin {
    margin-top: 19px;
  }

  .btn_right-margin {
    margin-right: 23px;
  }

  .send-emial-passowrd {
    background: $Cerulean 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px $SilverChalice;
    border-radius: 10px;
    opacity: 1;
    height: 38px;
    color: $White;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    width: 240px;
  }

  .reset-passowrd-btn {
    width: 179px;
    background: $Cerulean 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px $SilverChalice;
    border-radius: 10px;
    opacity: 1;
    height: 38px;
    color: $White;
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-icon {
    opacity: 1;
    height: 17px;
    padding-right: 12px;
  }

  .label {
    font: normal normal bold 16px $common-font;
    letter-spacing: 1.6px;
    color: $White;
    text-transform: uppercase;
    opacity: 1;
  }

  .reset-text {
    text-align: left;
    font: normal normal bold 25px $header-font;
    letter-spacing: 0px;
    color: $White;
    opacity: 1;
    padding-bottom: 20px;
  }

  .password-info-box {
    width: fit-content;
    height: fit-content;
    min-width: 200px;
    background: $LilyWhite 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    color: $Tundora;
    margin-top: 23px;
    @media (max-width: 1100px) {
      margin-top: 0;
    }
  }
  .info-title {
    padding-left: 10px;
  }
  .reset-container {
    display: flex;
    flex-flow: nowrap;
    @media (max-width: 1100px) {
      flex-flow: wrap;
    }
  }

  .remember-group {
    width: 85%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    .link {
      color: $Cerulean;
      font-size: $s-common-font-size;
    }
  }

  .remember-container {
    display: flex;
    align-items: center;
  }

  .remember-label {
    letter-spacing: 0.8px;
    color: $White;
    opacity: 1;
    font-size: $s-common-font-size;
    font-weight: 400;
  }
}

.MuiPaper-elevation1 {
  box-shadow: 0px 1px 3px 0px lightgray;
}

.MuiTab-root {
  @media (min-width: 600px) {
    min-width: 90px;
  }
}

.ant-picker-dropdown {
  z-index: 2000;
}
