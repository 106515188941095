.__table_pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--table-pagination-height);
  width: 100%;

  border-top: 1px solid #444;

  .__table_pages {
    display: flex;

    margin: 0 16px;
  }
}
