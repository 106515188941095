@import 'assets/scss/variables';

.upper-block-wrapper {
  margin-bottom: 16px;

  .schedule-modal-date-wrapper {
    display: flex;
    justify-content: center;

    gap: 20px;

    .schedule-modal-date-displayed-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 25px;

      .schedule-modal-date-block {
        display: flex;
        flex-direction: column;

        .schedule-modal-date-block-label {
          color: $Cerulean;
          text-transform: uppercase;
          letter-spacing: 1.6px;
          font: normal normal normal 16px/24px Akkurat-Bold;
        }

        .schedule-modal-date-block-value {
          display: flex;
          justify-content: center;
          min-width: 150px;
          min-height: 50px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e2e2e2;
          border-radius: 5px;

          padding: 12px;
        }
      }

      .schedule-modal-date-block-arrow-icon {
        display: flex;
        justify-content: center;

        svg {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .use-for-all-wrapper {
    display: flex;
    justify-content: center;
  }
}
