@import 'assets//scss/variables';

.input-wrapper {
  display: flex;
  flex-direction: column;

  .custom-input-label {
    font-family: $common-font;
    font-weight: 600;
    font-size: 16px;
    color: $Cerulean;
    letter-spacing: 2px;
    margin-bottom: 6px;
  }

  .input-label {
    padding-bottom: 11px;

    font: normal normal bold 16px $common-font;
    color: $White;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1.6px;

    opacity: 1;
  }

  .MuiInputBase-input {
    font-size: $common-font-size;
  }

  .MuiInputBase-root.Mui-error {
    border: 1px solid red;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 16px;

    border: none;
  }

  .input-field {
    font-size: $common-font-size;

    border-radius: 5px;

    & > div {
      & > input {
        box-sizing: border-box;
        height: 42px;

        background: #fff 0 0 no-repeat padding-box;
        border-radius: 5px;
      }
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0) scale(0.75);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $Cerulean;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $Cerulean;
  }

  .MuiInput-underline::after {
    border-bottom: 2px solid $Cerulean;
  }
}
