.centre-wrapper {
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  gap: 10px;

  .select-wrapper {
    width: 100%;
  }
}
